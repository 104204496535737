<template>
  <div id="header">
    <b-navbar>
        <template slot="end">
            <b-navbar-item tag="div">
                <div class="buttons" @click="logout">
                    <a class="button is-light">
                      Выйти
                    </a>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style lang="scss">
#header {
    z-index: 1;
}
.navbar-menu, .navbar-brand {
  background: #25292e;
}
.navbar-burger {

  span {
    color: white;
  }
}
.navbar-end {
  .buttons {
    justify-content: flex-end;
  }
}
</style>
