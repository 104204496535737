<template>
  <div id="app">
    <custom-header></custom-header>
    <div class="sidemenu-content-wrapper">
      <custom-side-menu></custom-side-menu>
      <router-view class="main-content"></router-view>
    </div>
  </div>
</template>

<script>
import CustomSideMenu from './CustomSideMenu'
import CustomHeader from './CustomHeader'

export default {
  name: 'MainPage',
  components: {
    CustomSideMenu,
    CustomHeader
  }
}
</script>

<style>
html, body {
  height: 100%;
}
.main-content {
  padding: 30px;
  width: 100%;
}
.sidemenu-content-wrapper {
  height: calc(100% - 52px);
  min-height: calc(100vh - 52px);
  display: flex;
}
</style>
