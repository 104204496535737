<template>
  <div id="side-menu">
    <b-menu>
      <b-menu-list>
        <b-menu-item
          :active="isActiveMenuItem('Home')"
          label="Главная"
          tag="router-link"
          :to="returnUrlPath('Home')"
        ></b-menu-item>
        <b-menu-item
          :active="isActiveMenuItem('Users')"
          :label="$t('title.users')"
          tag="router-link"
          :to="returnUrlPath('Users')"
        ></b-menu-item>
        <b-menu-item
          :active="isActiveMenuItem('Sites')"
          :label="$t('users.sites')"
          tag="router-link"
          :to="returnUrlPath('Sites')"
        ></b-menu-item>
        <b-menu-item
          :active="isActiveMenuItem('Tariffs')"
          :label="$t('tariffs.title')"
          tag="router-link"
          :to="returnUrlPath('Tariffs')"
        ></b-menu-item>
        <b-menu-item
          :active="isActiveMenuItem('Analytics')"
          :label="$t('analytics.title')"
          tag="router-link"
          :to="returnUrlPath('Analytics')"
        ></b-menu-item>
        <b-menu-item
          :active="isActiveMenuItem('Leads')"
          :label="$t('leads.leads')"
          tag="router-link"
          :to="returnUrlPath('Leads')"
        ></b-menu-item>
        <b-menu-item
          :active="isActiveMenuItem('Recommendations')"
          :label="$t('recommendations.title')"
          tag="router-link"
          :to="returnUrlPath('Recommendations')"
        ></b-menu-item>
        <b-menu-item
          :label="$t('mailganer_page')"
          :active="isActiveMenuItem('MailganerStat') || isActiveMenuItem('DeleteFromStoplist')"
        >
          <b-menu-item 
            :label="$t('mailganer_statistic')" 
            tag="router-link"
            :active="isActiveMenuItem('MailganerStat')"
            :to="returnUrlPath('MailganerStat')"
            ></b-menu-item>
          <b-menu-item 
            :label="$t('delete_from_stoplist_mailganer')"
            tag="router-link"
            :active="isActiveMenuItem('DeleteFromStoplist')"
            :to="returnUrlPath('DeleteFromStoplist')"
          ></b-menu-item>
          
        </b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<script>
  export default {
    name: 'SideMenu',
    created() {},
    methods: {
      returnUrlPath(name, params = {}) {
        const props = this.$router.resolve({
          name: name,
          params: params
        })
        return props.route.fullPath
      },
      isActiveMenuItem(item_name) {
        return this.$route.name === item_name
      }
    }
  }
</script>

<style lang="scss">
  #side-menu {
    height: auto;
    background: #25292e;
    padding: 0 10px;

    * {
      color: white;
    }
    .menu-list a:not(.is-active):hover {
      * {
        color: #25292e !important;
      }
    }
  }
</style>
